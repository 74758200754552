









































import { Component, Prop } from 'vue-property-decorator'

// components
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// store
import { CalendarEntityType, EducationMasterGroupResource } from '@/store/types'
// utils
import { subjectsColor, subjectsListOnID } from '@/utils/constants'

@Component({
  components: {
    Tag,
  },
})
export default class ManagerCalendarLegendItem extends MasterGroupMixin {
  @Prop({ required: true })
  private form!: Record<number, CalendarEntityType[]>

  @Prop({ required: true })
  private group!: EducationMasterGroupResource

  private innerValue: CalendarEntityType[] = []
  private all = false

  private mounted() {
    this.innerValue = this.form[this.group.id] ?? []
    this.all = !!this.innerValue.length
    this.$bus.$on('resetMentorCalendarFilters', this.resetFilters)
  }

  private destroyed() {
    this.$bus.$off('resetMentorCalendarFilters', this.resetFilters as any)
  }

  private getSubjectColor(subjectID: number) {
    return subjectsColor[subjectsListOnID[subjectID]]
  }

  private resetFilters() {
    this.innerValue = []
    this.all = false
    this.$emit('change', [], this.group.id)
  }

  private handleChange(value: CalendarEntityType[]) {
    this.all = !!value.length
    this.$emit('change', value, this.group.id)
  }

  private handleAll(value: boolean) {
    if (value) {
      this.innerValue = [CalendarEntityType.EXERCISE, CalendarEntityType.LESSON, CalendarEntityType.USEFUL_MATERIAL]
      this.$emit('change', this.innerValue, this.group.id)
    } else {
      this.resetFilters()
    }
  }
}
