

















































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'
import vuescroll from 'vuescroll'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ManagerCalendarLegendItem from '@/views/manager/calendar/ManagerCalendarLegendItem.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
// mixins
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MentorCalendarModule from '@/store/modules/mentor/calendar'
import { CalendarEntityType } from '@/store/types'

@Component({
  components: {
    Dialog,
    ManagerCalendarLegendItem,
    ModalWrapper,
  },
})
export default class ManagerCalendarLegend extends Mixins(DetectSafariMixin, MasterGroupMixin, NotifyMixin) {
  @Ref() scrollModal!: vuescroll

  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private form!: Record<number, CalendarEntityType[]>

  private get masterGroupsSelect() {
    return MentorCalendarModule.masterGroupsSelect
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private group = 0
  private isLoading = false
  private search = ''

  private handleClose() {
    this.$emit('update:visible', false)
  }

  private resetFilters() {
    this.$bus.$emit('resetMentorCalendarFilters')
  }

  private saveCalendarFilters() {
    if (!(Object.keys(this.form).reduce((acc, cur) => this.form[cur as unknown as number].length + acc, 0))) {
      this.notifyError('Выберите фильтры')
    } else {
      this.$emit('saveFilters')
      this.handleClose()
    }
  }

  private handleChange(value: CalendarEntityType[], id: number) {
    this.form[id] = value
  }

  @Bind()
  @Debounce(500)
  private fetchMasterGroups(query: string) {
    if (this.isLoading) return

    this.isLoading = true
    MentorCalendarModule.fetchMasterGroups({ query, masterGroupIds: query ? undefined: Object.keys(this.form) as unknown as number[] })
      .catch(this.notifyError)
      .finally(() => this.isLoading = false)
  }
}
